import { Modal, Form, Select } from 'antd';

import { useGetPJ, useAddPJ } from './quries';
import { ProjectInfoType } from '../types';

interface PropsType {
  visible: boolean;
  onCancel: () => void;
  projectInfo: ProjectInfoType;
  page: number;
  size: number;
  search: string;
}

export default function PJModal({ visible, onCancel, projectInfo, page, size, search }: PropsType) {
  const [form] = Form.useForm();

  const { data, isLoading } = useGetPJ();
  const { mutate, isLoading: isAddLoading } = useAddPJ({ onCancel, page, size, search });

  const onOk = async () => {
    const { company_code }: { company_code: Array<string> } = await form.validateFields();

    mutate({
      projectId: projectInfo.projectId,
      company_code: data?.filter(({ companyName }) => company_code.includes(companyName)).map(({ id }) => id),
    });
  };

  if (isLoading) return null;

  return (
    <Modal title="PJ오너" visible={visible} onCancel={onCancel} onOk={onOk} confirmLoading={isAddLoading}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="company_code"
          label="회사명"
          initialValue={data
            ?.filter(({ companyName }) => projectInfo.company_names?.split(',').includes(companyName))
            .map(({ companyName }) => companyName)}
        >
          <Select placeholder="회사명 선택" mode="multiple">
            {data?.map(({ companyName }) => (
              <Select.Option key={companyName}>{companyName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
