import { Tabs } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function SettingTabs() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onChange = (key: string) => {
    navigate(key);
  };

  return (
    <>
      <Tabs type="card" activeKey={pathname} onChange={onChange}>
        <TabPane tab="PJ 오너" key="/setting/pj" />
        <TabPane tab="카테고리설정" key="/setting/work_category" />
        <TabPane tab="사용법" key="/setting/service_use" />
      </Tabs>

      <Outlet></Outlet>
    </>
  );
}

export default SettingTabs;

const TabPane = Tabs.TabPane;
