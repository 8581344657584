import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useCodeResend, usePaginatedMasterTableData } from './queries';
import usePaginatedQuries from '@hooks/usePaginatedQuries';
import useModal from '@hooks/useModal';
import { masterTableType, useStatusType } from './types';
import AddModal from './Modal/AddModal';
import { Table, Dropdown, Row, Menu, Space, Button, Modal } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TableTotalCount } from '@components/common/styles';

const { confirm } = Modal;

const USE_STATUS: useStatusType = {
  Y: '사용',
  N: '미사용',
};

const ProjectMasterTable = () => {
  const navigate = useNavigate();
  const { pathname, page, size } = usePaginatedQuries();
  const { visible, openModal, closeModal } = useModal();
  const { data: projects, isLoading } = usePaginatedMasterTableData({ page, size });
  const { mutate: mutateCode } = useCodeResend();

  const showCodeConfirm = (id: number) => {
    confirm({
      title: '코드를 재전송 하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        mutateCode(id);
      },
    });
  };

  const folderMenu = (record: masterTableType) => {
    return (
      <Menu>
        <Menu.Item key="code" onClick={() => showCodeConfirm(record.id)}>
          코드 재전송
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: '프로젝트명',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '유형',
      dataIndex: 'code_detail_name',
      key: 'code_detail_name',
    },
    {
      title: '서비스 종류',
      dataIndex: 'service_title',
      key: 'service_title',
    },
    {
      title: '인원',
      dataIndex: 'people',
      key: 'people',
    },
    {
      title: '생성일',
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: '생성일',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: Date) => text && dayjs(text).format('YY.MM.DD'),
    },
    {
      title: '담당자',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: '사용일',
      dataIndex: 'use_date',
      key: 'use_date',
      render: (text: Date) => text && dayjs(text).format('YY.MM.DD'),
    },
    {
      title: '사용자',
      dataIndex: 'use_name',
      key: 'use_name',
    },
    {
      title: '생성코드',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '사용유무',
      dataIndex: 'use_yn',
      key: 'use_yn',
      render: (text: string) => <span>{USE_STATUS[text]}</span>,
    },

    {
      title: '',
      dataIndex: 'setting',
      width: 20,
      render: (_: string, record: masterTableType) => (
        <Dropdown overlay={folderMenu(record)} trigger={['click']}>
          <Row justify="center">
            <EllipsisOutlined style={{ cursor: 'pointer' }}></EllipsisOutlined>
          </Row>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <Row justify="space-between" align="middle">
          <TableTotalCount>{projects && `총 ${projects.count}개 프로젝트`}</TableTotalCount>

          <Button type="primary" onClick={openModal}>
            신규생성
          </Button>
        </Row>
        <Table
          columns={columns}
          dataSource={projects ? projects.list.map((item: masterTableType) => ({ ...item, key: item.id })) : []}
          bordered={true}
          pagination={{
            current: page,
            pageSize: size,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            total: projects?.count,
            onChange: (page, pageSize) => navigate(`${pathname}?page=${page}&size=${pageSize}`),
          }}
          loading={isLoading}
        ></Table>
      </Space>

      {visible && (
        <AddModal title="신규생성" visible={visible} onCancel={closeModal} page={page} size={size}></AddModal>
      )}
    </>
  );
};

export default ProjectMasterTable;
