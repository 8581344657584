import { useState } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';

import { WorkCategoryTableDataType, CategoryListType } from './types';
import { PROJECT_CLASSIFICATION } from '@constants/setting';
import useModal from '@hooks/useModal';
import EditModal from './EditModal';
import { useEffect } from 'react';

interface PropsType {
  data?: WorkCategoryTableDataType[];
  isLoading: boolean;
}

function WorkCategoryTable({ data, isLoading }: PropsType) {
  const { visible, closeModal, openModal } = useModal();
  const [workCategoryInfo, setWorkCategoryInfo] = useState(INITIAL_DATA);

  useEffect(() => {
    setWorkCategoryInfo((prev) => ({
      ...prev,
      categoryList: data?.find((data) => data.classification === prev.classification)?.categoryList as [],
      classification: data?.find((data) => data.classification === prev.classification)?.classification as string,
    }));
  }, [data]);

  const columns = [
    {
      title: '유형',
      dataIndex: 'classification',
      key: 'classification',
      width: 100,
      render: (value: string) => PROJECT_CLASSIFICATION[value],
    },
    {
      title: '카테고리명',
      dataIndex: 'categoryList',
      key: 'categoryList',
      render: (value: CategoryListType[]) => value.map((data) => data.name).join(','),
    },
    {
      title: '',
      dataIndex: 'setting',
      key: 'setting',
      width: 90,
      render: (_: any, { classification, categoryList }: WorkCategoryTableDataType) => {
        return {
          props: {
            style: {
              textAlign: 'center',
            },
          },
          children: (
            <StyledButton
              onClick={() => {
                openModal();
                setWorkCategoryInfo({
                  classification,
                  categoryList,
                });
              }}
            >
              수정
            </StyledButton>
          ),
        };
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={data?.map((data) => ({ ...data, key: data.classification }))}
        columns={columns}
        bordered
        loading={isLoading}
        pagination={false}
      />

      {visible && <EditModal visible={visible} workCategoryInfo={workCategoryInfo} onCancel={closeModal} />}
    </>
  );
}

export default WorkCategoryTable;

const INITIAL_DATA = {
  classification: '',
  categoryList: [{ id: '', name: '' }],
};

const StyledButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: #55c58b;
  &:hover {
    cursor: pointer;
  }
`;
