import { useState } from 'react';
import { Modal, Form, Button, Upload, message } from 'antd';
import type { UploadFile, RcFile } from 'antd/es/upload/interface';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import axios from 'axios';

import { presignedURL } from '@components/setting/service/queries';
import { PresignedURLType, PresignedListType } from '@components/setting/service/types';

export default function UploadModal({ visible, onCancel }: { visible: boolean; onCancel: () => void }) {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const mutation = useMutation({ mutationFn: presignedURL });

  const onOk = async () => {
    const { files }: { files: Array<UploadFile> } = await form.validateFields();

    if (files[0]?.type?.split('/')[1] !== 'pdf') {
      return message.warning({ content: 'pdf 파일 형식만 업로드 가능합니다.', key: 'warning' });
    }

    setLoading(() => true);
    handlePresigned(files);
  };

  const handlePresigned = async (files: Array<UploadFile>) => {
    try {
      const { data }: { data: PresignedURLType } = await mutation.mutateAsync();

      handleS3ToUpload({ data: data.data, files });
    } catch (error) {
      const err = error as Error;
      message.error({ content: err.message, key: 'error' });
    }
  };

  const handleS3ToUpload = async ({ data, files }: { data: PresignedListType; files: Array<UploadFile> }) => {
    try {
      await data?.presignedList.map((data) => {
        const formData = new FormData();
        for (const key in data.fields) {
          formData.append(key, data.fields?.[key] as string);
        }
        formData.append('file', files?.[0]?.originFileObj as RcFile);
        formData.append('Content-Type', files?.[0]?.type as string);

        return axios.post(data.url as string, formData).then(() => {
          setLoading(() => false);
          onCancel();
          message.success({ content: '업로드 되었습니다.', key: 'success' });
        });
      });
    } catch (error) {
      setLoading(() => false);
      message.error({ content: '오류가 발생했습니다. 관리자에게 문의해주세요.', key: 'error' });
    }
  };

  return (
    <Modal title="업로드" visible={visible} onCancel={onCancel} onOk={onOk} confirmLoading={isLoading}>
      <StyledContent>파일 업로드 시 기존파일을 대체합니다.</StyledContent>
      <Form form={form} layout="vertical">
        <Form.Item
          name="files"
          label="파일추가"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          required
          rules={[{ required: true, message: '추가 하실 파일을 선택해주세요.' }]}
        >
          <StyledUpload multiple customRequest={dummyRequest} maxCount={1} accept=".pdf">
            <Button style={{ width: '100%' }}>로컬 드라이브</Button>
          </StyledUpload>
        </Form.Item>
      </Form>
    </Modal>
  );
}

const StyledContent = styled.div`
  font-size: 14px;
  color: #a0a0a0;
  margin-bottom: 16px;
`;

const StyledUpload = styled(Upload)`
  div.ant-upload {
    width: 100%;
  }
`;

const normFile = (e: any) => {
  const fileList = e.fileList;

  if (Array.isArray(e)) {
    return e;
  }
  return e && fileList;
};

const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};
