import { Space } from 'antd';

import { useGetWorkCategoryTableData } from './queries';
import Header from './Header';
import WorkCategoryTable from './WorkCategoryTable';

function WorkCategoryComponent() {
  const { data, isLoading } = useGetWorkCategoryTableData();

  return (
    <Space size="middle" direction="vertical" style={{ width: '100%' }}>
      <Header />
      <WorkCategoryTable data={data} isLoading={isLoading} />
    </Space>
  );
}

export default WorkCategoryComponent;
