import { useState } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';

import { useGetPJ } from './queries';
import Buttons from './Buttons';
import useModal from '@hooks/useModal';
import EditModal from './EditModal';

export default function PJTable() {
  const { data, isLoading } = useGetPJ();
  const { visible, openModal, closeModal } = useModal();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<React.Key>>([]);
  const [selectedRowInfo, setSelectedRowInfo] = useState({
    companyId: 0,
    companyName: '',
    companyCode: '',
  });

  const columns = [
    {
      title: '회사명',
      key: 'companyName',
      dataIndex: 'companyName',
    },
    {
      title: '사업자 등록번호',
      key: 'companyCode',
      dataIndex: 'companyCode',
    },
    {
      title: '진행PJ',
      key: 'activeProjectCount',
      dataIndex: 'activeProjectCount',
      width: 100,
    },
    {
      title: '중지PJ',
      key: 'stoppedProjectCount',
      dataIndex: 'stoppedProjectCount',
      width: 100,
    },
    {
      title: '종료PJ',
      key: 'completedProjectCount',
      dataIndex: 'completedProjectCount',
      width: 100,
    },
    {
      title: '',
      key: 'setting',
      dataIndex: 'setting',
      width: 60,
      render: (
        _: any,
        { companyName, companyCode, companyId }: { companyName: string; companyCode: string; companyId: number }
      ) => (
        <StyledButton
          onClick={() => {
            openModal();
            setSelectedRowInfo({
              companyId,
              companyCode,
              companyName,
            });
          }}
        >
          수정
        </StyledButton>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(() => selectedRowKeys);
    },
  };

  return (
    <>
      <Buttons selectedRowKeys={selectedRowKeys} />
      <Table
        dataSource={data?.map((data) => ({ ...data, key: data.companyId }))}
        columns={columns}
        size="small"
        bordered
        rowSelection={rowSelection}
        loading={isLoading}
      />
      {visible && <EditModal visible={visible} onCancel={closeModal} selectedRowInfo={selectedRowInfo} />}
    </>
  );
}

const StyledButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: #55c58b;
  &:hover {
    cursor: pointer;
  }
`;
