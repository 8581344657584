import Template from '@components/template/Template';
import SettingTabs from '@components/setting';

const UserManagementPage = () => {
  return (
    <Template>
      <SettingTabs />
    </Template>
  );
};

export default UserManagementPage;
