import { ReactComponent as Logo } from '@assets/icons/headerLogo.svg';
import { PRIMARY_COLOR } from '@components/common/styles';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

export const CustomizedHeader = styled(Header)`
  background: ${PRIMARY_COLOR};
  color: #fff;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
`;

export const LogoWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #48b67d;
  cursor: pointer;
`;

export const HeaderLogo = styled(Logo)`
  width: 200px;
  height: 24px;
`;

export const HeaderInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  height: inherit;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

export const NavTitle = styled.div`
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const UserInfo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`;

export const DropDownIcon = styled(DownOutlined)`
  color: #fff !important;
  font-size: 12px;
  cursor: pointer;
`;

export const CustomizedSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  top: 44px;
  z-index: 10;

  .ant-menu-submenu-selected {
    color: ${PRIMARY_COLOR};
  }
`;

export const CustomizedMenu = styled(Menu)`
  height: 100%;
`;

export const CustomizeContent = styled(Content)`
  background-color: #fff;
  padding: 24px;
`;
