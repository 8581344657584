import { useState } from 'react';
import { Modal, Form, Input, Radio, Col, Row, message, Button } from 'antd';

import { useAddRegistration, useEditPJ } from './queries';

interface PropsType {
  visible: boolean;
  onCancel: () => void;
  selectedRowInfo: {
    companyId: number;
    companyName: string;
    companyCode: string;
  };
}

export default function EditModal({ visible, onCancel, selectedRowInfo }: PropsType) {
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState(selectedRowInfo.companyCode ? 'yes' : 'no');

  const { mutate: codeMutate } = useAddRegistration();
  const { mutate: editMutate, isLoading } = useEditPJ(onCancel);

  const onOk = async () => {
    const { companyName, companyCode } = await form.validateFields();

    editMutate({
      companyId: selectedRowInfo.companyId,
      companyName,
      companyCode,
    });
  };

  return (
    <Modal title="PJ오너 수정" visible={visible} onCancel={onCancel} onOk={onOk} confirmLoading={isLoading}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="companyName"
          label="회사명"
          rules={[{ required: true, message: '회사명을 입력하세여.' }]}
          initialValue={selectedRowInfo.companyName}
        >
          <Input placeholder="회사명" />
        </Form.Item>
        <Form.Item
          name=""
          label="사업자등록번호"
          rules={[{ required: radioValue === 'no' ? false : true, message: '사입자등록번호를 입력해주세요.' }]}
          initialValue={radioValue}
          style={{ marginBottom: '8px' }}
        >
          <Radio.Group
            onChange={(e) => {
              if (e.target.value === 'no') {
                form.resetFields(['companyCode']);
              }
              setRadioValue(e.target.value);
            }}
          >
            <Radio value="no">해당없음</Radio>
            <Radio value="yes">기입</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="companyCode" label="">
          <Row gutter={24}>
            <Col span={20}>
              <Input
                defaultValue={radioValue === 'yes' ? selectedRowInfo.companyCode : ''}
                disabled={radioValue === 'no' ? true : false}
                placeholder="사업자등록번호"
              />
            </Col>
            <Col span={4}>
              <Button
                onClick={() => {
                  form.getFieldValue(['companyCode'])
                    ? codeMutate({ companyCode: form.getFieldValue(['companyCode']) })
                    : message.warning({ content: '사업자등록번호를 입력해주세요.', key: 'warning' });
                }}
                disabled={radioValue === 'no' ? true : false}
                type="primary"
              >
                인증
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}
