import { Modal, Form, Select } from 'antd';

import { useGetConnectionList, useAddConnection } from './quries';

interface Props {
  visible: boolean;
  onCancel: () => void;
  projectInfo: {
    projectId: number;
    link_by_category?: Record<string, string>;
  };
  status: number;
  page: number;
  size: number;
  search: string;
}

export default function ConnectionModal({ visible, onCancel, projectInfo, status, page, size, search }: Props) {
  const [form] = Form.useForm();
  const { projectId, link_by_category } = projectInfo;

  const { data, isLoading } = useGetConnectionList();
  const { mutate, isLoading: isAddLoading } = useAddConnection({ status, page, size, search, onCancel });

  const aiCompanyList = [
    ...(data?.map(({ companyName }) => ({ value: companyName, label: companyName })) ?? []),
    { value: '해당없음', label: '해당없음' },
  ];

  const onOk = async () => {
    const { code }: { code: string } = await form.validateFields();
    const company_code = code === '해당없음' ? null : data?.find(({ companyName }) => code === companyName)?.id ?? 0;

    mutate({ projectId, company_code });
  };

  if (isLoading) return null;

  return (
    <Modal title="유료연동" visible={visible} onCancel={onCancel} onOk={onOk} confirmLoading={isAddLoading}>
      <Form form={form} layout="vertical">
        <Form.Item name="code" label="종류1 / AI 연동" initialValue={link_by_category?.ai || '해당없음'}>
          <Select options={aiCompanyList} placeholder="선택" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
