import { Result, Button } from 'antd';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';

const PageNotFoundPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClick = () => {
    navigate(-1);
  };

  if (pathname === '/') {
    return <Navigate to="/project"></Navigate>;
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="페이지를 찾을 수 없습니다."
      extra={
        <Button type="primary" onClick={onClick}>
          돌아가기
        </Button>
      }
    />
  );
};

export default PageNotFoundPage;
