import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export const DeleteConfirmModal = ({ title, content, onOk }: { title: string; onOk: () => void; content?: string }) => {
  confirm({
    title,
    content,
    onOk() {
      onOk();
    },
    icon: <ExclamationCircleOutlined />,
  });
};
