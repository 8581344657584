import { Row, Button } from 'antd';

import useModal from '@hooks/useModal';
import AddModal from './AddModal';

function Header() {
  const { visible, closeModal, openModal } = useModal();
  return (
    <>
      <Row justify="end">
        <Button onClick={openModal}>추가</Button>
      </Row>

      {visible && <AddModal visible={visible} onCancel={closeModal} />}
    </>
  );
}

export default Header;
