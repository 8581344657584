import { Modal, Form, Select } from 'antd';

import { useGetAppPermission, useEditAppPermission } from './quries';
import { ProjectInfoType } from '../types';

interface Props {
  visible: boolean;
  onCancel: () => void;
  projectInfo: ProjectInfoType;
}

export default function AppDownloadModal({ visible, onCancel, projectInfo }: Props) {
  const { projectId } = projectInfo;
  const [form] = Form.useForm();

  const { data, isLoading } = useGetAppPermission(projectId);
  const { mutate, isLoading: isEditLoading } = useEditAppPermission(onCancel);

  const onOk = async () => {
    const { permission }: { permission: string } = await form.validateFields();
    mutate({
      projectId,
      permission,
    });
  };

  if (isLoading) return null;

  return (
    <Modal
      title="앱내 파일 다운로드 허용"
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={isEditLoading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="permission"
          label="옵션"
          initialValue={data?.app_download_permission}
          style={{ marginBottom: 0 }}
        >
          <Select
            options={[
              { label: '비허용', value: 'none' },
              { label: '드라이브허용', value: 'driveOnly' },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
