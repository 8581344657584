import { Outlet, Navigate } from 'react-router-dom';

import { useUserInfo } from '../store/store';

const Auth = () => {
  const info = useUserInfo();

  return info.isLogin ? <Outlet /> : <Navigate to="/login" replace />;
};

export default Auth;
