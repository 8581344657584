import Template from '@components/template/Template';
import ProjectTabs from '@components/project/ProjectTabs';

const ProjectsPage = () => {
  return (
    <Template>
      <ProjectTabs></ProjectTabs>
    </Template>
  );
};

export default ProjectsPage;
