import { memo, useRef } from 'react';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { useDownloadCSV } from './queries';
import { Button } from 'antd';

const CSV_HEADERS = [
  {
    key: 'id',
    label: 'id',
  },
  {
    key: 'email',
    label: '이메일',
  },
  {
    key: 'full_name',
    label: '이름',
  },
  {
    key: 'phone_number',
    label: '전화번호',
  },
  {
    key: 'companyName',
    label: '소속명',
  },
  {
    key: 'position',
    label: '직함',
  },
  {
    key: 'created_at',
    label: '가입일',
  },
];

const UserCSVDownloadButton = memo(function UserCSVDownloadButton({ type }: { type: string }) {
  const { data, isFetching, refetch } = useDownloadCSV(type);
  const CSVLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const handleDownloadCSV = async () => {
    await refetch();
    CSVLinkRef.current?.link.click();
  };

  return (
    <>
      <Button type="primary" onClick={handleDownloadCSV} loading={isFetching}>
        CSV
      </Button>
      <CSVLink
        headers={CSV_HEADERS}
        data={data}
        filename={`가입회원_${dayjs(new Date()).format('YYYY.MM.DD')}.csv`}
        ref={CSVLinkRef}
      ></CSVLink>
    </>
  );
});

export default UserCSVDownloadButton;
