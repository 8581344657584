import { useClassificationData, useNewProject, useSerivcePlanData } from './quries';
import { useNavigate } from 'react-router-dom';
import { modalType } from '@components/common/types';
import { AsteriskText } from '@components/common/styles';
import { Modal, Form, Input, Radio } from 'antd';

const AddModal = ({ title, visible, onCancel, page, size }: { page: number; size: number } & modalType) => {
  const [form] = Form.useForm();
  const { data: classficationData } = useClassificationData();
  const { data: servicePlanData } = useSerivcePlanData();
  const navigate = useNavigate();

  const handleMutateSuccess = () => {
    onCancel();
    navigate(`/project/master?page=1&size=${size}`);
  };

  const { mutate: mutateNewProject } = useNewProject(handleMutateSuccess, page, size);

  const onOk = async () => {
    const result = await form.validateFields();
    mutateNewProject(result);
  };

  return (
    <Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form
        form={form}
        initialValues={{
          classification: 'A00',
          plan: 9,
        }}
        layout="vertical"
        name="add_form_modal"
      >
        <Form.Item
          name="title"
          label="프로젝트명"
          rules={[
            {
              required: true,
              message: '프로젝트명을 입력하세요.',
            },
          ]}
        >
          <Input></Input>
        </Form.Item>

        <Form.Item name="classification" label="유형">
          <Radio.Group>
            {classficationData?.map(
              ({ code_detail, code_detail_name }: { code_detail: string; code_detail_name: string }) => (
                <Radio.Button key={code_detail} value={code_detail}>
                  {code_detail_name}
                </Radio.Button>
              )
            )}
          </Radio.Group>
        </Form.Item>

        <Form.Item name="plan" label="서비스 종류">
          <Radio.Group>
            {servicePlanData?.map(({ id, title }: { id: number; title: string }) => (
              <Radio.Button key={id} value={id}>
                {title}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="people"
          label="인원"
          rules={[
            {
              required: true,
              message: '인원수를 입력하세요.',
            },
            {
              pattern: /^[0-9]*$/,
              message: '숫자로 인원 수를 입력해주세요.',
            },
          ]}
        >
          <Input placeholder="프로젝트 참여 인원"></Input>
        </Form.Item>
        <Form.Item
          name="period"
          label="사용기간"
          rules={[
            {
              required: true,
              message: '사용기간을 입력하세요.',
            },
            {
              pattern: /^[0-9]*$/,
              message: '숫자로 사용기간을 입력해주세요.',
            },
          ]}
        >
          <Input placeholder="사용 개월 수"></Input>
        </Form.Item>

        <Form.Item
          name="name"
          label="담당자"
          rules={[
            {
              required: true,
              message: '담당자 이름을 입력하세요.',
            },
          ]}
        >
          <Input placeholder="담당자 이름"></Input>
        </Form.Item>

        <Form.Item
          name="email"
          label="이메일"
          rules={[
            {
              required: true,
              message: '이메일을 입력하세요.',
            },
            {
              type: 'email',
              message: '올바르지 않은 이메일 형식입니다.',
            },
          ]}
        >
          <Input placeholder="이메일"></Input>
        </Form.Item>
      </Form>
      <AsteriskText asterisk={true}>위 정보를 기입하면, 신청자에게 PJ코드가 이메일로 발송됩니다.</AsteriskText>
    </Modal>
  );
};

export default AddModal;
