import { useMutation, useQuery, useQueryClient } from 'react-query';
import { message } from 'antd';

import { PJListType } from './types';
import api from '@utils/api';

const getPJ = async () => {
  const { data } = await api.get('/company/detailList');
  return data as Array<PJListType>;
};

export const useGetPJ = () => {
  return useQuery([`pjList`], () => getPJ(), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
};

const addPJ = async ({ companyName, companyCode }: { companyName: string; companyCode: string }) => {
  const { data } = await api.post('/company/create', { companyName, companyCode });
  return data;
};

export const useAddPJ = (onCancel: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(addPJ, {
    onSuccess: () => {
      onCancel();
      message.success({ content: '추가되었습니다.', key: ' success' });
      queryClient.invalidateQueries(['pjList']);
    },
    onError: (error: Error) => {
      const err = error as any;
      message.error({ content: err.data.message, key: 'error' });
    },
  });
};

const addRegistration = async ({ companyCode }: { companyCode: string }) => {
  const { data } = await api.post('/company/registration', { companyCode });
  return data;
};

export const useAddRegistration = () => {
  return useMutation(addRegistration, {
    onSuccess: () => {
      message.success({ content: '인증되었습니다.', key: 'success' });
    },
    onError: (error: Error) => {
      const err = error as any;
      message.error({ content: err.data.message, key: 'error' });
    },
  });
};

const deletePJ = async (company_code: React.Key[]) => {
  const { data } = await api.delete('/company/delete', { data: { company_code } });
  if (data.result === -1) {
    throw Error(data.message);
  }
  return data;
};

export const useDeletePJ = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePJ, {
    onSuccess: () => {
      message.success({ content: '삭제되었습니다.', key: 'success' });
      queryClient.invalidateQueries(['pjList']);
    },
    onError: (error: Error) => {
      message.error({ content: error.message, key: 'error' });
    },
  });
};

const editPJ = async ({
  companyId,
  companyName,
  companyCode,
}: {
  companyId: number;
  companyName: string;
  companyCode: string;
}) => {
  const { data } = await api.put(`/company/${companyId}`, { companyName, companyCode });
  return data;
};

export const useEditPJ = (onCancel: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(editPJ, {
    onSuccess: () => {
      onCancel();
      message.success({ content: '수정되었습니다.', key: 'success' });
      queryClient.invalidateQueries(['pjList']);
    },
    onError: (error: Error) => {
      const err = error as any;
      message.error({ content: err.data.message, key: 'error' });
    },
  });
};
