import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { UserInfoType } from '../components/template/quries';

interface UserType {
  info: UserInfoType & { isLogin: boolean };
  token: {
    accessToken: string;
  };
  actions: {
    setUserInfo: (data: UserInfoType) => void;
    setToken: (data: string) => void;
  };
}

const DEFAULT_USER_INFO = {
  email: '',
  id: 0,
  name: '',
  refresh_token: '',
  isLogin: false,
};

export const useUserStore = create(
  persist<UserType>(
    (set) => ({
      info: DEFAULT_USER_INFO,
      token: {
        accessToken: '',
      },
      actions: {
        setUserInfo: (data: UserInfoType) =>
          set(() => ({
            info: {
              id: data.id,
              email: data.email,
              name: data.name,
              refresh_token: data.refresh_token,
              isLogin: true,
            },
          })),
        setToken: (data: string) => set(() => ({ token: { accessToken: data } })),
      },
    }),
    {
      name: 'userInfo',
      partialize: ({ actions, token, ...rest }: any) => rest,
    }
  )
);

export const useUserInfo = () => useUserStore((state) => state.info);
export const useToken = () => useUserStore((state) => state.token);
export const useUserActions = () => useUserStore((state) => state.actions);
