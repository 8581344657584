import { Button, message, Row, Space, Modal } from 'antd';
import styled from 'styled-components';

import { useDeletePJ } from './queries';
import AddModal from './AddModal';
import useModal from '@hooks/useModal';

interface PropsType {
  selectedRowKeys: Array<React.Key>;
}

export default function Buttons({ selectedRowKeys }: PropsType) {
  const { visible, openModal, closeModal } = useModal();
  const { mutate, isLoading } = useDeletePJ();

  return (
    <>
      <StyledRow justify="end">
        <Space>
          <Button onClick={openModal}>추가</Button>
          <Button
            loading={isLoading}
            onClick={() =>
              selectedRowKeys.length
                ? Modal.confirm({
                    title: '선택한 회사를 삭제하시겠습니까?',
                    okType: 'danger',
                    onOk: () => mutate(selectedRowKeys),
                  })
                : message.warning({ content: '삭제하실 회사를 선택해주세요.', key: 'warning' })
            }
          >
            삭제
          </Button>
        </Space>
      </StyledRow>

      {visible && <AddModal visible={visible} onCancel={closeModal} />}
    </>
  );
}

const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;
