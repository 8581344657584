import { Modal, Form, Space, Input, Col, Row, Popconfirm, Empty, Button } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import { WorkCategoryTableDataType } from './types';
import { useEditWorkCategory, useDeleteWorkCategory } from './queries';

interface PropsType {
  visible: boolean;
  workCategoryInfo: WorkCategoryTableDataType;
  onCancel: () => void;
}

function EditModal({ visible, workCategoryInfo, onCancel }: PropsType) {
  const [form] = Form.useForm();

  const { mutate: editMutate, isLoading } = useEditWorkCategory(onCancel);
  const { mutate: deleteMutate } = useDeleteWorkCategory();

  const onOk = async () => {
    if (workCategoryInfo.categoryList === undefined) return onCancel();

    const { itemList }: { itemList: [{ id: string; name: string }] } = await form.validateFields();

    editMutate({
      itemList,
      project_classification: workCategoryInfo.classification,
    });
  };

  const button = {
    render: (add: () => void) => (
      <Row gutter={22}>
        <Col span={22}>
          <Button type="dashed" onClick={() => add()} block>
            추가하기
          </Button>
        </Col>
      </Row>
    ),
  };

  return (
    <Modal title="카테고리명" visible={visible} onCancel={onCancel} onOk={onOk} confirmLoading={isLoading}>
      <Form form={form} layout="vertical">
        <Form.List name="itemList" initialValue={workCategoryInfo.categoryList}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} direction="vertical" style={{ width: '100%' }}>
                  <Row gutter={22} align="middle">
                    <Col span={22}>
                      <Form.Item {...field} name={[field.name, 'name']} label="카테고리명">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Popconfirm
                      title="선택한 정보를 삭제하시겠습니까?"
                      onConfirm={() =>
                        deleteMutate({
                          id: workCategoryInfo.categoryList.find((_, index) => index === field.name)?.id,
                          remove: () => remove(field.name),
                        })
                      }
                    >
                      <MinusCircleOutlined style={{ fontSize: 20, marginLeft: 10 }} />
                    </Popconfirm>
                  </Row>
                </Space>
              ))}
              {button.render(add)}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default EditModal;
