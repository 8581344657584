import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@constants/project';

const usePaginatedQuries = () => {
  const { pathname, search: searchQuries } = useLocation();
  const { page, size, search } = qs.parse(searchQuries, { ignoreQueryPrefix: true });
  return {
    pathname,
    page: page ? Number(page) : DEFAULT_PAGE,
    size: size ? Number(size) : DEFAULT_PAGE_SIZE,
    search: search ? String(search) : '',
  };
};

export default usePaginatedQuries;
