import { Row, Space, Button, Table } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetProjectDocsCount } from './queries';

export default function ProjectDocs() {
  const { data, columns, handleGoBack, projectName } = useProjectDocs();

  return (
    <Space direction="vertical" size="middle" style={StyledSpace}>
      <Row justify="space-between" align="middle">
        <div>{projectName}</div>
        <Button onClick={handleGoBack}>돌아가기</Button>
      </Row>

      <Table
        dataSource={data?.map((data) => ({ ...data, key: data.gubun, gubun: DOCS_LIST[data.gubun] }))}
        columns={columns}
        size="small"
        pagination={false}
      />
    </Space>
  );
}

const useProjectDocs = () => {
  const { projectId, menu } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const projectName = decodeURIComponent(search.split('=')[1] ?? '');
  const { data } = useGetProjectDocsCount(projectId);

  const columns = [
    {
      title: '문서명',
      dataIndex: 'gubun',
      key: 'gubun',
    },
    {
      title: '생성갯수',
      dataIndex: 'total_count',
      key: 'total_count',
    },
    {
      title: '작성중',
      dataIndex: 'in_progress_count',
      key: 'in_progress_count',
    },
    {
      title: '전자결재중',
      dataIndex: 'approval_progress_count',
      key: 'approval_progress_count',
    },
    {
      title: '전자결재완료',
      dataIndex: 'complete_count',
      key: 'complete_count',
    },
  ];

  const handleGoBack = () => {
    navigate(`/project/${menu}?page=1&size=10`);
  };

  return { data, columns, handleGoBack, projectName };
};

const StyledSpace = {
  width: '100%',
};

const DOCS_LIST: { [key: string]: string } = {
  work: '작업일보',
  worker: '출역일보',
  'inspection-request': '검측문서',
  'material-auth': '자재승인',
  'material-inspection': '반입자재검수요청서',
  'material-receipt': '자재수불부',
  'concrete-plan': '타설계획서',
  'concrete-crack': '균열관리',
  'quality-concrete': '레미콘품질',
  'work-order': '작업지시서(회의록)',
  total: '합계',
};
