import { useQuery } from 'react-query';

import { useUserActions } from '../../store/store';
import api from '@utils/api';

export interface UserInfoType {
  email: string;
  id: number;
  name: string;
  refresh_token: string;
}

export const getUserInfoData = async () => {
  const { data } = await api.get('/auth/info');
  return data as UserInfoType;
};

export const useUserInfoData = () => {
  const { setUserInfo } = useUserActions();
  return useQuery(['project-user-info'], getUserInfoData, {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setUserInfo(data);
    },
  });
};
