import styled from 'styled-components';

export const PRIMARY_COLOR = '#55c58b';

export const AsteriskText = styled.p<{ asterisk?: boolean }>`
  ${({ asterisk }) =>
    asterisk &&
    `
      &::before {
        color: #ff4d4f;
        font-size: 14px;
        margin-right: 2px;
        line-height: 1;
        content: '*';
      }
      font-size: 12px;
      color: #707070;
    `}
  font-size: 12px;
  color: #707070;
`;

export const TableTotalCount = styled.div`
  font-size: 14px;
  color: #5f6368;
  font-weight: bold;
`;
