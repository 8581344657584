import Header from './Header';
import Sider from './Sider';
import { CustomizeContent } from './styles';
import { Layout } from 'antd';

const Template = ({ children }: { children: JSX.Element }) => {
  return (
    <Layout style={{ height: '100vh' }}>
      <Header></Header>
      <Layout style={{ paddingTop: '44px' }}>
        <Sider></Sider>
        <Layout style={{ marginLeft: '200px' }}>
          <CustomizeContent>{children}</CustomizeContent>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Template;
