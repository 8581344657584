import { useQuery } from 'react-query';

import { queryKeys } from '@components/setting/queryKeys';
import { ServiceDataType } from '@components/setting/service/types';
import api from '@utils/api';

const getServiceData = async () => {
  const { data } = await api.get(`v1/service-manual`);
  return data as ServiceDataType;
};

export const useGetServiceData = () => {
  return useQuery(queryKeys.serviceTableData(), () => getServiceData(), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
};

export const presignedURL = () => {
  return api.post(`v1/service-manual/web`);
};
