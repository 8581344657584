import { Button, Upload } from 'antd';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useUploadCSV } from './queries';

import usePaginatedQuries from '@hooks/usePaginatedQuries';

const UserJoinButton = () => {
  const { page, size, search } = usePaginatedQuries();
  const { mutate: uploadMutate, isLoading } = useUploadCSV({ page, size, search });

  const handleUpload = ({ file }: RcCustomRequestOptions) => {
    uploadMutate({
      file,
    });
  };

  return (
    <Upload customRequest={handleUpload} showUploadList={false}>
      <Button loading={isLoading}>회원가입</Button>
    </Upload>
  );
};

export default UserJoinButton;
