import { Modal, Form, Input, Button, Row, Col, Space, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import { PROJECT_CLASSIFICATION_SELECT_DATA } from '@constants/setting';
import { useAddWorkCategory } from './queries';

interface PropsType {
  visible: boolean;
  onCancel: () => void;
}

function AddModal({ visible, onCancel }: PropsType) {
  const [form] = Form.useForm();

  const { mutate: addMutate, isLoading } = useAddWorkCategory(onCancel);

  const onOk = async () => {
    const {
      project_classification,
      name,
      formItems,
    }: { project_classification: string; name: string; formItems: { name: string }[] } = await form.validateFields();

    const nameList: string[] = formItems ? [name, ...formItems.map(({ name }) => name)] : [name];

    addMutate({
      project_classification,
      nameList,
    });
  };

  return (
    <Modal title="카테고리명" visible={visible} onCancel={onCancel} onOk={onOk} confirmLoading={isLoading}>
      <Form form={form} layout="vertical">
        <Row gutter={22}>
          <Col span={22}>
            <Form.Item
              name="project_classification"
              label="유형"
              required
              rules={[{ required: true, message: '유형을 선택해주세요.' }]}
            >
              <Select placeholder="유형 선택">
                {PROJECT_CLASSIFICATION_SELECT_DATA.map((data) => (
                  <Select.Option key={data.value} value={data.value}>
                    {data.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={22}>
            <Form.Item
              name="name"
              label="카테고리명"
              required
              rules={[{ required: true, message: '공종명을 입력하세요.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="formItems">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} direction="vertical" style={{ width: '100%' }}>
                  <Row gutter={22} align="middle">
                    <Col span={22}>
                      <Form.Item {...field} name={[field.name, 'name']} label="카테고리명">
                        <Input />
                      </Form.Item>
                    </Col>
                    <MinusCircleOutlined onClick={() => remove(field.name)} style={{ fontSize: 20, marginLeft: 10 }} />
                  </Row>
                </Space>
              ))}
              <Row gutter={22}>
                <Col span={22}>
                  <Button type="dashed" onClick={() => add()} block>
                    추가하기
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default AddModal;
