import axios from 'axios';
import { useUserStore } from '../store/store';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use(
  (config) => {
    const { accessToken } = useUserStore.getState().token;
    if (config.headers && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { refresh_token, id } = useUserStore.getState().info;
        const { data } = await api.post('/auth/refresh', {
          refresh_token,
          user_id: id,
        });
        const { status_code, access_token } = data;
        if (status_code === 500) {
          throw error;
        }
        useUserStore.getState().actions.setToken(access_token);
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return api(originalRequest);
      } catch (error) {
        localStorage.removeItem('userInfo');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default api;
