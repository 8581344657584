import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { usePaginatedDeleteTableData, usePaginatedJoinTableData, useDeleteUserAccount } from './queries';
import usePaginatedQuries from '@hooks/usePaginatedQuries';
import { USER_JOINED, USER_DEACTIVATED } from '@constants/users';
import { TableType, tableDataType } from './types';
import UserCSVDownloadButton from './UserCSVDownloadButton';
import { TableTotalCount } from '@components/common/styles';
import { Table, Dropdown, Row, Space, Input, Menu, Modal } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import UserJoinButton from './UserJoinButton';
import { DeleteConfirmModal } from '@components/common/ConfirmModal';
import { useWithdrawalUser, useResetPassword } from '@components/userManagement/queries';

const { Search } = Input;
const { confirm } = Modal;

const UserJoinedTable = ({ type }: { type: string }) => {
  const navigate = useNavigate();
  const { pathname, page, size, search } = usePaginatedQuries();
  const { data: users, isLoading } = usePaginatedJoinTableData({ page, size, search, enabled: type === USER_JOINED });
  const { data: deletedUsers, isLoading: deletedIsLoading } = usePaginatedDeleteTableData({
    page,
    size,
    search,
    enabled: type === USER_DEACTIVATED,
  });
  const { mutate: deleteMutate } = useDeleteUserAccount({ page, size, search });
  const { mutate: withdrawalMutate } = useWithdrawalUser({ page, size, search });
  const tableData: tableDataType = type === USER_JOINED ? users : deletedUsers;

  const { mutate: resetMutate } = useResetPassword();

  const deleteUserAccountConfirm = (id: number) => {
    confirm({
      title: '선택한 계정을 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteMutate(id);
      },
      okText: '확인',
      cancelText: '취소',
    });
  };

  const folderMenu = (id: number) => {
    return (
      <Menu>
        {type === USER_JOINED ? (
          <>
            <Menu.Item
              key="deactivate"
              onClick={() =>
                DeleteConfirmModal({
                  title: '선택한 계정을 탈퇴처리 하시겠습니까?',
                  onOk: () => withdrawalMutate({ userId: id }),
                })
              }
            >
              탈퇴처리
            </Menu.Item>
            <Menu.Item
              key="reset"
              onClick={() =>
                DeleteConfirmModal({
                  title: '비밀번호 초기화를 진행하시겠습니까? xyz1234567 으로 변경됩니다.',
                  onOk: () => resetMutate(id),
                })
              }
            >
              비밀번호 초기화
            </Menu.Item>
          </>
        ) : (
          <Menu.Item key="delete" onClick={() => deleteUserAccountConfirm(id)}>
            삭제
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const columns = [
    {
      title: '이름',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: '소속명',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: '직함',
      dataIndex: 'position',
      key: 'position',
    },

    {
      title: '전화번호',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '가입일',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: Date) => text && dayjs(text).format('YY.MM.DD'),
    },

    {
      title: '',
      dataIndex: 'setting',
      width: 20,
      render: (_: string, { id }: { id: number }) => (
        <Dropdown overlay={folderMenu(id)} trigger={['click']}>
          <Row justify="center">
            <EllipsisOutlined style={{ cursor: 'pointer' }}></EllipsisOutlined>
          </Row>
        </Dropdown>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Row justify="space-between" align="middle">
        {isLoading || deletedIsLoading ? (
          <LoadingOutlined spin style={{ color: '#55c58b' }} />
        ) : (
          <TableTotalCount>{`총 인원수 : ${tableData?.count}명`}</TableTotalCount>
        )}
        <Space>
          <UserJoinButton />
          <UserCSVDownloadButton type={type}></UserCSVDownloadButton>
          <Search
            onSearch={(value) => navigate(`${pathname}?page=${page}&size=${size}&search=${value}`)}
            allowClear
            placeholder="회원 이름"
            style={{ width: '300px' }}
          />
        </Space>
      </Row>
      <Table
        columns={columns}
        dataSource={tableData?.list.map((item: TableType) => ({ ...item, key: item.id }))}
        pagination={{
          current: page,
          pageSize: size,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          total: tableData?.count,
          onChange: (page, pageSize) => navigate(`${pathname}?page=${page}&size=${pageSize}`),
        }}
        loading={isLoading || deletedIsLoading}
        bordered={true}
      ></Table>
    </Space>
  );
};

export default UserJoinedTable;
