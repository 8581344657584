import Template from '@components/template/Template';
import UserManagementTabs from '@components/userManagement/UserManagementTabs';

const UserManagementPage = () => {
  return (
    <Template>
      <UserManagementTabs></UserManagementTabs>
    </Template>
  );
};

export default UserManagementPage;
