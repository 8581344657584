import dayjs from 'dayjs';
import UserLoginLogButton from './UserLoginLogButton';
import { modalType } from '@components/common/types';
import { useGetUserList, useGetLogList } from './quries';
import { Modal, Form, DatePicker, Select, Button } from 'antd';

const { Option } = Select;

const UserLoginLogModal = ({ title, visible, onCancel, id }: modalType & { id: number }) => {
  const [form] = Form.useForm();
  const { data } = useGetUserList(id);
  const { data: csvData, mutateAsync } = useGetLogList();

  const onOk = async () => {
    const { date, ids } = await form.validateFields();
    const [startDate, endDate] = date;
    await mutateAsync({
      ids,
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
    });
  };

  return (
    <Modal
      title={title}
      visible={visible}
      footer={[
        <Button key="submit" onClick={onCancel}>
          취소
        </Button>,

        <UserLoginLogButton
          key="download"
          data={csvData?.map((data) => ({ ...data, created_at: dayjs(data.created_at).format('YYYY-MM-DD') }))}
          onClick={onOk}
          onCancel={onCancel}
        ></UserLoginLogButton>,
      ]}
    >
      <Form form={form} layout="vertical" name="add_form_modal">
        <Form.Item
          label="참여자 목록"
          name="ids"
          rules={[
            {
              required: true,
              message: '참여자를 선택하세요.',
            },
          ]}
        >
          <Select
            filterOption={(
              inputValue: string,
              option: { children: string; key: number; title: string; value: string } | undefined
            ) => {
              if (option) {
                return option.children.includes(inputValue);
              }
              return true;
            }}
            placeholder="찾기"
            mode="multiple"
            allowClear
          >
            {data?.map(({ id, name }: { id: number; name: string }) => (
              <Option key={id}>{name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="일자"
          name="date"
          rules={[
            {
              required: true,
              message: '일자를 선택하세요.',
            },
          ]}
        >
          <DatePicker.RangePicker style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserLoginLogModal;
