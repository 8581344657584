import { Table } from 'antd';
import styled from 'styled-components';

import { useGetServiceData } from '@components/setting/service/queries';
import useModal from '@hooks/useModal';
import UploadModal from '@components/setting/service/UploadModal';

export default function ServiceComponent() {
  const { data, isLoading } = useGetServiceData();

  const { visible, closeModal, openModal } = useModal();

  const columns = [
    {
      title: '종류',
      key: 'type',
      dataIndex: 'type',
      width: 200,
    },
    {
      title: '파일명',
      key: 'fileName',
      dataIndex: 'fileName',
    },
    {
      title: '',
      key: 'setting',
      dataIndex: 'setting',
      width: '',
      render: () => <StyledButton onClick={openModal}>업로드</StyledButton>,
    },
  ];

  return (
    <>
      <Table
        dataSource={data?.data.map((data) => ({
          ...data,
          key: data.type,
          type: TYPE_LIST[data.type],
          fileName: '크로스팀_사용가이드.pdf',
        }))}
        columns={columns}
        size="small"
        loading={isLoading}
      />

      {visible && <UploadModal visible={visible} onCancel={closeModal} />}
    </>
  );
}

const TYPE_LIST: { [key: string]: string } = {
  WM: '사용설명서',
};

const StyledButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: #55c58b;
  &:hover {
    cursor: pointer;
  }
`;
