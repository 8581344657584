import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@constants/project';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const ProjectTabs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { projectId } = useParams();

  const onChange = (key: string) => {
    const convertPath = (projectId && key.split('/')) ?? [];
    const path = convertPath.length ? `/${convertPath[1]}/${convertPath[2]}` : key;
    navigate(`${path}?page=${DEFAULT_PAGE}&size=${DEFAULT_PAGE_SIZE}`);
  };

  const key = (menu: number) => {
    return projectId ? `/project/${menu}/docs/${projectId}` : `/project/${menu}`;
  };

  return (
    <>
      <Tabs type="card" onChange={onChange} activeKey={pathname}>
        <TabPane tab="진행중" key={key(1)}></TabPane>
        <TabPane tab="중지" key={key(2)}></TabPane>
        <TabPane tab="종료" key={key(3)}></TabPane>
        <TabPane tab="삭제예정" key={key(0)}></TabPane>
        <TabPane tab="마스터생성" key="/project/master"></TabPane>
      </Tabs>
      <Outlet></Outlet>
    </>
  );
};

export default ProjectTabs;
