import { useRef } from 'react';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';

type loginLogType = {
  data?: { email: string; name: string; created_at: string }[];
  onClick: () => void;
  onCancel: () => void;
};

const CSV_HEADERS = [
  // {
  //   key: 'id',
  //   label: 'id',
  // },
  {
    key: 'name',
    label: '이름',
  },
  {
    key: 'email',
    label: '이메일',
  },
  {
    key: 'created_at',
    label: '가입일',
  },
];

const UserLoginLogButton = ({ data, onClick, onCancel }: loginLogType) => {
  const CSVLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const handleDownloadCSV = async () => {
    await onClick();
    CSVLinkRef.current?.link.click();
    onCancel();
  };

  return (
    <>
      <Button onClick={handleDownloadCSV} type="primary">
        확인
      </Button>
      {data && (
        <CSVLink
          headers={CSV_HEADERS}
          data={data}
          filename={`${dayjs(new Date()).format('YYYY.MM.DD')}.csv`}
          ref={CSVLinkRef}
        ></CSVLink>
      )}
    </>
  );
};

export default UserLoginLogButton;
