import { useState, useEffect } from 'react';
import { Modal, Form, Select } from 'antd';
import { useGetGroupList, useGetMemberList, useEditManagerChange } from './quries';
import { ProjectInfoType } from '../types';

interface PropsType {
  visible: boolean;
  onCancel: () => void;
  projectInfo: ProjectInfoType;
  handleResetProjectId: () => void;
  status: number;
}

function EditManagerModal({ visible, onCancel, projectInfo, handleResetProjectId, status }: PropsType) {
  const [form] = Form.useForm();
  const { projectId, created_user_id } = projectInfo;
  const [groupId, setGroupId] = useState(0);

  const { data: groupList, isLoading } = useGetGroupList({ projectId });
  const { data: memberList } = useGetMemberList({ projectId, groupId, created_user_id });
  const { mutate: editMutate, isLoading: isEditLoading } = useEditManagerChange({ onCancel, status });

  const onOk = async () => {
    const result: { groupId: number; userId: number } = await form.validateFields();
    const { groupId, ...editInfo } = result;

    editMutate({
      projectId,
      editInfo,
    });
  };

  const onSelect = (value: number) => {
    setGroupId(value);
  };

  useEffect(() => {
    return () => handleResetProjectId();
  }, []);

  if (isLoading) return null;

  return (
    <Modal title="담당자 변경" visible={visible} onCancel={onCancel} onOk={onOk} confirmLoading={isEditLoading}>
      <Form form={form} layout="vertical">
        <Form.Item name="groupId" label="소속명" rules={[{ required: true, message: '소속명을 선택해주세요.' }]}>
          <Select placeholder="소속명 선택" onSelect={onSelect} options={groupList} />
        </Form.Item>
        <Form.Item name="userId" label="이름" rules={[{ required: true, message: '이름을 선택해주세요.' }]}>
          <Select placeholder="이름 선택" options={memberList} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditManagerModal;
