export const WORK_CATEGORY_PATH = '/setting/pj';

export const PROJECT_CLASSIFICATION: { [key: string]: string; A00: string; B00: string; C00: string } = {
  A00: '일반',
  B00: '공동주택',
  C00: '토목',
};

export const PROJECT_CLASSIFICATION_SELECT_DATA = [
  {
    id: 'A00',
    value: 'A00',
    label: '일반',
  },
  { id: 'B00', value: 'B00', label: '공동주택' },
  { id: 'C00', value: 'C00', label: '토목' },
];
