import styled from 'styled-components';
import { Input, Button } from 'antd';
import { ReactComponent as Logo } from '@assets/icons/loginLogo.svg';

export const LoginPagerWrapper = styled.div`
  background-color: #eff1f2;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginSection = styled.div`
  width: 500px;
  padding: 38px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d0d0d0;
  background-color: #fff;
`;

export const LoginLogo = styled(Logo)`
  width: 240px;
`;

export const LoginInput = styled(Input)`
  input {
    height: 52px !important;
  }
  .ant-input-group-addon {
    width: 52px;
  }
`;

export const PasswordInput = styled(Input.Password)`
  .ant-input {
    height: 42px !important;
    padding: 4px 11px 4px 0px !important;
  }
  .ant-input-group-addon {
    width: 52px;
  }
`;

export const Buttons = styled(Button)`
  width: 100%;
  height: 58px !important;
`;
