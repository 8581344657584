import { useQuery, useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { PROJECT_STATUS_ONGOING } from '@constants/project';
import { projectType, selectDataType, editManagerChangeParameterType, CompanyPJType } from '../types';
import { message } from 'antd';

import usePaginatedQuries from '@hooks/usePaginatedQuries';
import api from '@utils/api';

const getServicePlanData = async () => {
  const { data } = await api.get('/project-code-creation/service');
  return data;
};

export const useSerivcePlanData = () => {
  return useQuery(['project-master-service'], getServicePlanData, {
    refetchOnWindowFocus: false,
  });
};

const getClassificationData = async () => {
  const { data } = await api.get('/project-code-creation/classification');
  return data;
};

export const useClassificationData = () => {
  return useQuery(['project-master-classfication'], getClassificationData, {
    refetchOnWindowFocus: false,
  });
};

const addNewProject = async (project: projectType) => {
  const { data } = await api.post('/project-code-creation', { project });
  return data;
};

export const useNewProject = (handleMutateSuccess: () => void, page: number, pageSize: number) => {
  const queryClient = useQueryClient();
  return useMutation(addNewProject, {
    onSuccess: () => {
      message.success('새로운 프로젝트가 생성되었습니다.');
      queryClient.invalidateQueries(['project-master-list', page, pageSize]);
      handleMutateSuccess();
    },
  });
};

const restartProject = async ({
  plan,
  people,
  endDate,
  id,
  total_size,
}: {
  plan: string;
  people: number;
  endDate: string;
  id: number;
  total_size?: number;
}) => {
  const { data } = await api.post(`/projects/${id}/reactivation`, {
    plan: plan.toString(),
    people: +people,
    endDate,
    total_size,
  });
  return data;
};

export const useRestartProject = (handleMutateSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(restartProject, {
    onSuccess: () => {
      message.success('선택한 프로젝트가 재활성 되었습니다.');
      handleMutateSuccess();
      queryClient.refetchQueries(['project-table-list', PROJECT_STATUS_ONGOING, 1, 1, '']);
    },
  });
};

const getUserList = async (id: number) => {
  const { data } = await api.get(`/projects/log/${id}`);
  return data;
};

export const useGetUserList = (id: number) => {
  return useQuery([`project-user-log-${id}`], () => getUserList(id), {
    refetchOnWindowFocus: false,
    onError: (error: AxiosError) => {
      const { message: errorMessage } = error.response?.data;
      message.error({ content: errorMessage, key: 'error' });
    },
  });
};

const getLogList = async ({ ids, start_date, end_date }: { ids: number; start_date: string; end_date: string }) => {
  const { data } = await api.post('/projects/log-detail', { ids, start_date, end_date });
  return data as Array<{ name: string; email: string; created_at: string }>;
};

export const useGetLogList = () => {
  return useMutation(getLogList, {
    onSuccess: () => {
      // queryClient.refetchQueries(['project-table-list', PROJECT_STATUS_ONGOING, 1, 1, '']);
    },
  });
};

const getGroupList = async ({ projectId }: { projectId: number }): Promise<selectDataType[]> => {
  const { data } = await api.get(`/projects/${projectId}/admin-groups`);
  return data;
};

export const useGetGroupList = ({ projectId }: { projectId: number }) => {
  return useQuery([`manager-change-groups-${projectId}`], () => getGroupList({ projectId }), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
};

const getMemberList = async ({
  projectId,
  groupId,
  created_user_id,
}: {
  projectId: number;
  groupId: number;
  created_user_id?: number;
}): Promise<selectDataType[]> => {
  const { data } = await api(`/project-user/${projectId}/${groupId}/?created_user_id=${created_user_id}`);
  return data;
};

export const useGetMemberList = ({
  projectId,
  groupId,
  created_user_id,
}: {
  projectId: number;
  groupId: number;
  created_user_id?: number;
}) => {
  return useQuery(
    [`manager-change-member-${projectId}-${groupId}`],
    () => getMemberList({ projectId, groupId, created_user_id }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!groupId,
    }
  );
};

const editManagerChange = async ({ projectId, editInfo }: editManagerChangeParameterType) => {
  const { data } = await api.patch(`/projects/${projectId}/owner`, { editInfo });
  if (data.result === -1) {
    throw Error(data.message);
  }
  return data;
};

export const useEditManagerChange = ({
  onCancel,
  status,
}: {
  onCancel: () => void;
  status: number;
}): UseMutationResult<editManagerChangeParameterType, AxiosError, editManagerChangeParameterType> => {
  const queryClient = useQueryClient();
  const { page, size, search } = usePaginatedQuries();

  return useMutation(editManagerChange, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project-table-list', status, page, size, search]);
      message.success({ content: '수정되었습니다', key: 'success' });
      onCancel();
    },
    onError: (error) => {
      message.error({ content: error.message, key: 'error' });
    },
  });
};

const editProjectInfo = ({
  projectId,
  editInfo,
}: {
  projectId: number;
  editInfo: { memberCount: number; storageSize: number; endDate: string };
}) => {
  return api.patch(`/projects/${projectId}/usage`, editInfo);
};

export const useEditProjectInfo = ({
  page,
  size,
  search,
  onCancel,
}: {
  page: number;
  size: number;
  search: string;
  onCancel: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(editProjectInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries(['project-table-list', 1, page, size, search]);
      message.success({ content: '수정되었습니다.', key: 'success' });
      onCancel();
    },
    onError: (error: AxiosError) => {
      message.error({ content: error.response?.data.message, key: 'error' });
    },
  });
};

const getPJ = async () => {
  const { data } = await api.get('/company/list');
  return data as Array<CompanyPJType>;
};

export const useGetPJ = () => {
  return useQuery([`company-pj`], () => getPJ(), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
};

const addPJ = async ({ projectId, company_code }: { projectId: number; company_code?: Array<number> }) => {
  const { data } = await api.post(`/company/${projectId}/project`, { company_code });
  if (data.result === -1) {
    throw Error(data.message);
  }
  return data;
};

export const useAddPJ = ({
  onCancel,
  page,
  size,
  search,
}: {
  onCancel: () => void;
  page: number;
  size: number;
  search: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation(addPJ, {
    onSuccess: () => {
      onCancel();
      message.success({ content: '수정되었습니다.', key: 'success' });
      queryClient.invalidateQueries(['project-table-list', 1, page, size, search]);
    },
    onError: (error: Error) => {
      message.error({ content: error.message, key: 'error' });
    },
  });
};

const getConnectionList = async () => {
  const { data } = await api.get('/link/list');
  return data as Array<{ id: number; companyName: string; category: string }>;
};

export const useGetConnectionList = () => {
  return useQuery([`connectionList`], () => getConnectionList(), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
};

const addConnection = async ({ projectId, company_code }: { projectId: number; company_code: null | number }) => {
  const { data } = await api.post(`link/${projectId}/project`, { company_code });
  if (data.result === -1) {
    throw Error(data.message);
  }
  return data;
};

export const useAddConnection = ({
  status,
  page,
  size,
  search,
  onCancel,
}: {
  status: number;
  page: number;
  size: number;
  search: string;
  onCancel: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(addConnection, {
    onSuccess: () => {
      onCancel();
      message.success({ content: '추가되었습니다.', key: 'success' });
      queryClient.invalidateQueries(['project-table-list', status, page, size, search]);
    },
    onError: (error: Error) => {
      message.error({ content: error.message, key: 'error' });
    },
  });
};

const getAppPermission = async (projectId: number) => {
  const { data } = await api.get(`projects/${projectId}/app-permission`);
  return data as { app_download_permission: string };
};

export const useGetAppPermission = (projectId: number) => {
  return useQuery(['app-permission', { projectId }], () => getAppPermission(projectId), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
};

const editAppPermission = async ({ projectId, permission }: { projectId: number; permission: string }) => {
  const { data } = await api.patch(`projects/${projectId}/app-permission`, { permission });
  if (data.result === -1) {
    throw Error(data.message);
  }
  return data;
};

export const useEditAppPermission = (onCancel: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(editAppPermission, {
    onSuccess: (_, { projectId }) => {
      queryClient.invalidateQueries(['app-permission', { projectId }]);
      message.success({ content: '수정되었습니다.', key: 'success' });
      onCancel();
    },
    onError: (error: AxiosError) => {
      const { message: errorMessage } = error.response?.data;
      message.error({ content: errorMessage, key: 'error' });
    },
  });
};
