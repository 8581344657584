import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@constants/project';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const UserManagementTabs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onChange = (key: string) => {
    navigate(`${key}?page=${DEFAULT_PAGE}&size=${DEFAULT_PAGE_SIZE}`);
  };

  return (
    <>
      <Tabs type="card" onChange={onChange} activeKey={pathname}>
        <TabPane tab="가입회원" key="/user"></TabPane>
        <TabPane tab="탈퇴회원" key="/user/delete"></TabPane>
      </Tabs>

      <Outlet></Outlet>
    </>
  );
};

export default UserManagementTabs;
