import { useState } from 'react';
import { Modal, Form, Input, Radio, Col, Row, Button, message } from 'antd';

import { useAddPJ, useAddRegistration } from './queries';

interface PropsType {
  visible: boolean;
  onCancel: () => void;
}

export default function AddModal({ visible, onCancel }: PropsType) {
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState('no');
  const [code, setCode] = useState('');

  const { mutate, isLoading } = useAddPJ(onCancel);
  const { mutate: codeMutate } = useAddRegistration();

  const onOk = async () => {
    const { companyName, companyCode } = await form.validateFields();

    mutate({
      companyName,
      companyCode,
    });
  };

  const handleCodeMutate = () => {
    codeMutate(
      { companyCode: form.getFieldValue(['companyCode']) },
      { onSuccess: () => setCode(form.getFieldValue(['companyCode'])) }
    );
  };

  return (
    <Modal
      title="PJ 오너"
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      onOk={onOk}
      confirmLoading={isLoading}
      okButtonProps={{
        disabled: radioValue === 'no' || code ? false : true,
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="companyName" label="회사명" rules={[{ required: true, message: '회사명을 입력하세여.' }]}>
          <Input placeholder="회사명" />
        </Form.Item>
        <Form.Item
          name=""
          label="사업자등록번호"
          rules={[{ required: radioValue === 'no' ? false : true, message: '사입자등록번호를 입력해주세요.' }]}
          initialValue={radioValue}
          style={{ marginBottom: '8px' }}
        >
          <Radio.Group
            onChange={(e) => {
              if (e.target.value === 'no') {
                form.resetFields(['companyCode']);
              }
              setRadioValue(e.target.value);
            }}
          >
            <Radio value="no">해당없음</Radio>
            <Radio value="yes">기입</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="companyCode" label="">
          <Row gutter={24}>
            <Col span={20}>
              <Input disabled={radioValue === 'no' ? true : false} placeholder="사업자등록번호" />
            </Col>
            <Col span={4}>
              <Button
                onClick={() => {
                  form.getFieldValue(['companyCode'])
                    ? handleCodeMutate()
                    : message.warning({ content: '사업자등록번호를 입력해주세요.', key: 'warning' });
                }}
                disabled={radioValue === 'no' ? true : false}
                type="primary"
              >
                인증
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}
