import { useEffect } from 'react';
import { Modal, Form, InputNumber } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { DatePicker } from '@components/common';

import { useEditProjectInfo } from './quries';
import { ProjectInfoType } from '../types';

interface PropsType {
  visible: boolean;
  projectInfo: ProjectInfoType;
  page: number;
  size: number;
  search: string;
  onCancel: () => void;
  handleResetProjectId: () => void;
}

function EditProjectInfoModal({ visible, projectInfo, page, size, search, onCancel, handleResetProjectId }: PropsType) {
  const [form] = Form.useForm();

  const { mutate: editMutate, isLoading } = useEditProjectInfo({ page, size, search, onCancel });

  const onOk = async () => {
    const results: { memberCount: number; storageSize: number; endDate: dayjs.ConfigType } =
      await form.validateFields();

    const editInfo = {
      ...results,
      storageSize: results.storageSize * Math.pow(1024, 3),
      endDate: dayjs(results.endDate).format('YYYY-MM-DD'),
    };

    editMutate({
      projectId: projectInfo.projectId,
      editInfo,
    });
  };

  useEffect(() => {
    return () => handleResetProjectId();
  }, []);

  return (
    <Modal title="수정" visible={visible} onCancel={onCancel} onOk={onOk} confirmLoading={isLoading}>
      <Form form={form} layout="vertical">
        <Form.Item name="memberCount" label="인원" initialValue={projectInfo.memberCount}>
          <InputNumber placeholder="기입" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="storageSize"
          label="용량(GB)"
          initialValue={((projectInfo.storageSize as number) / (1024 * 1024 * 1024)).toFixed(2)}
        >
          <InputNumber placeholder="기입" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="endDate" label="기간" initialValue={dayjs(projectInfo.endDate)}>
          <DatePicker
            style={{ width: '100%' }}
            disabledDate={(current) => {
              return current <= dayjs(projectInfo.endDate).endOf('day');
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditProjectInfoModal;

dayjs.extend(weekday);
dayjs.extend(localeData);
