import { useQuery, useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { AxiosError } from 'axios';

import { queryKeys } from '@components/setting/queryKeys';
import {
  WorkCategoryTableDataType,
  AddWorkCategoryParameterType,
  EditWorkCategoryParameterType,
  DeleteWorkCategoryParameterType,
} from './types';
import api from '@utils/api';

const getWorkCategoryTableData = async () => {
  const { data } = await api.get('/setting-work-category/v1');
  return data.data as WorkCategoryTableDataType[];
};

export const useGetWorkCategoryTableData = () => {
  return useQuery(queryKeys.workCategoryTableData(), () => getWorkCategoryTableData(), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
};

const addWorkCategory = async ({ nameList, project_classification }: AddWorkCategoryParameterType) => {
  const { data } = await api.post('/setting-work-category/v1', { nameList, project_classification });
  return data;
};

export const useAddWorkCategory = (onCancel: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(addWorkCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.workCategoryTableData());
      message.success({ content: '추가되었습니다.', key: 'success' });
      onCancel();
    },
    onError: (error: AxiosError) => {
      message.error({ content: error.response?.data.message, key: 'error' });
    },
  });
};

const editWorkCategory = async ({ itemList, project_classification }: EditWorkCategoryParameterType) => {
  const { data } = await api.patch('/setting-work-category/v1', { itemList, project_classification });
  return data;
};

export const useEditWorkCategory = (onCancel: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(editWorkCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.workCategoryTableData());
      message.success({ content: '수정되었습니다.', key: 'success' });
      onCancel();
    },
    onError: (error: AxiosError) => {
      message.error({ content: error.response?.data.message, key: 'error' });
    },
  });
};

const deleteWorkCategory = async ({ id }: DeleteWorkCategoryParameterType) => {
  const { data } = await api.delete(`/setting-work-category/v1/${id}`);
  return data;
};

export const useDeleteWorkCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteWorkCategory, {
    onSuccess: (_, { remove }) => {
      queryClient.invalidateQueries(queryKeys.workCategoryTableData());
      message.success({ content: '삭제되었습니다.', key: 'success' });
      remove();
    },
    onError: (error: AxiosError) => {
      message.error({ content: error.response?.data.message, key: 'error' });
    },
  });
};
